import axios from '@axios'
import { ref } from '@vue/composition-api'

export default function useUnits() {
  const units = ref([])
  const searchUnit = ref('')
  const loading = ref(false)

  const getUnits = params => {
    loading.value = true

    return new Promise((resolve, reject) => {
      axios
        .get('/units', { params })
        .then(response => {
          const { data } = response
          units.value = data
          loading.value = false
          resolve(response)
        })
        .catch(error => {
          loading.value = false
          reject(error)
        })
    })
  }

  return {
    units,
    searchUnit,

    getUnits,
  }
}
