<template>
  <v-card>
    <v-card-title>{{ $router.currentRoute.params.id ? 'Edit' : 'New' }} Pre-Repair Inspection</v-card-title>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        v-if="$store.getters['auth/can'](['create pre-repairs'])"
        color="primary"
        class="mr-2"
        :disabled="saving"
        @click="save"
      >
        Save
      </v-btn>
      <v-btn
        v-if="$router.currentRoute.params.id"
        color="secondary"
        class="mr-2"
        outlined
        :disabled="exporting"
        @click="exportPdf"
      >
        <v-icon
          size="17"
          class="me-1"
        >
          {{ icons.mdiExportVariant }}
        </v-icon>
        Export PDF
      </v-btn>
    </v-card-actions>
    <v-card-text>
      <v-fade-transition>
        <v-alert
          v-model="showAlert"
          border="left"
          :color="alertType"
          dark
          text
          dismissible
        >
          {{ alertType === 'success' ? 'Job order saved.' : 'Please check all the required fields.' }}
        </v-alert>
      </v-fade-transition>
      <v-form class="multi-col-validation">
        <v-row>
          <v-col
            cols="12"
            md="4"
          >
            <v-autocomplete
              v-model="preRepairForm.vehicle_id"
              aria-required=""
              placeholder="Search by Plate Number.. ."
              :loading="loadingVehicle"
              :error-messages="errors.vehicle_id"
              :items="vehicles"
              :search-input.sync="searchVehicle"
              cache-items
              hide-details="auto"
              label="Vehicle"
              item-text="plate_number"
              item-value="id"
              outlined
              hide-no-data
              dense
              @keyup.enter="fetchVehicles"
            ></v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-menu
              v-model="issueDatePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="preRepairForm.issue_date"
                  label="Issue Date"
                  :prepend-icon="icons.mdiCalendarCheckOutline"
                  readonly
                  v-bind="attrs"
                  outlined
                  dense
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="preRepairForm.issue_date"
                color="primary"
                @input="issueDatePicker = false"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-menu
              v-model="completionDatePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="preRepairForm.completion_date"
                  label="Completion Date"
                  :prepend-icon="icons.mdiCalendarCheckOutline"
                  readonly
                  v-bind="attrs"
                  outlined
                  dense
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="preRepairForm.completion_date"
                color="primary"
                @input="completionDatePicker = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-textarea
              v-model="preRepairForm.defects"
              label="Defects/Complaints"
              rows="3"
              outlined
            ></v-textarea>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-textarea
              v-model="preRepairForm.findings"
              label="Findings"
              rows="3"
              outlined
            ></v-textarea>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <template>
      <v-tabs
        v-model="vehicleTab"
        fixed-tabs
      >
        <v-tab>
          <h4>Parts ({{ preRepairForm.pre_repair_parts.length }})</h4>
        </v-tab>
        <v-tab>
          <h4>Additional Details</h4>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="vehicleTab">
        <v-tab-item>
          <v-card>
            <v-card-text>
              <div class="add-services-form pb-10 px-8">
                <div
                  v-for="(part, partIndex) in preRepairForm.pre_repair_parts"
                  :key="partIndex"
                  class="single-service-form"
                  style="margin-top: 1rem"
                >
                  <div class="add-services-header mb-2 d-none d-md-flex">
                    <div class="px-5 flex-grow-1 font-weight-semibold">
                      <v-row>
                        <v-col
                          cols="12"
                          md="2"
                        >
                          <span>Unit</span>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <span>Description</span>
                        </v-col>
                        <v-col
                          cols="12"
                          md="2"
                        >
                          <span>Quantity</span>
                        </v-col>
                        <v-col
                          cols="12"
                          md="2"
                        >
                          <span>Preferred Brand</span>
                        </v-col>
                      </v-row>
                    </div>
                    <div class="header-spacer"></div>
                  </div>
                  <v-card
                    outlined
                    class="d-flex"
                  >
                    <!-- Left Form -->
                    <div class="pa-5 flex-grow-1">
                      <v-row>
                        <v-col
                          cols="12"
                          md="2"
                        >
                          <v-autocomplete
                            v-model="part.unit"
                            :items="units"
                            hide-details="auto"
                            item-text="name"
                            item-value="name"
                            outlined
                            dense
                            clearable
                            hide-no-data
                          ></v-autocomplete>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="part.description"
                            outlined
                            dense
                            clearable
                            hide-details="auto"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          md="2"
                          sm="4"
                        >
                          <v-text-field
                            v-model="part.quantity"
                            outlined
                            dense
                            min="0"
                            type="number"
                            hide-details="auto"
                            placeholder="$"
                            @input="
                              val => {
                                part.quantity = Math.abs(val)
                              }
                            "
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          md="2"
                        >
                          <v-text-field
                            v-model="part.preferred_brand"
                            outlined
                            dense
                            clearable
                            hide-details="auto"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </div>
                    <!-- Service Actions -->
                    <div class="d-flex flex-column item-actions rounded-0 pa-1">
                      <v-btn
                        icon
                        small
                        @click="preRepairForm.pre_repair_parts.splice(partIndex, 1)"
                      >
                        <v-icon size="20">
                          {{ icons.mdiClose }}
                        </v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                    </div>
                  </v-card>
                </div>
                <v-row>
                  <v-col
                    cols="12"
                    md="2"
                    offset="5"
                  >
                    <v-btn
                      color="primary"
                      class="mt-4 col-4"
                      outlined
                      block
                      @click="addNewPart"
                    >
                      Add Part
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-card class="my-10">
            <v-card-text>
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-autocomplete
                    v-model="preRepairForm.prepared_by"
                    aria-required=""
                    placeholder="Search.. ."
                    :loading="loadingPreparedBy"
                    :error-messages="errors.prepared_by"
                    :items="preparedByOptions"
                    :search-input.sync="searchPreparedBy"
                    cache-items
                    hide-details="auto"
                    label="Prepared By"
                    item-text="full_name"
                    item-value="id"
                    outlined
                    dense
                    hide-no-data
                    @keyup.enter="fetchPreparedBy(['App Admin', 'Mechanic', 'Engineer'])"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-autocomplete
                    v-model="preRepairForm.inspected_by"
                    aria-required=""
                    placeholder="Search.. ."
                    :loading="loadingInspectedBy"
                    :error-messages="errors.inspected_by"
                    :items="inspectedByOptions"
                    :search-input.sync="searchInspectedBy"
                    cache-items
                    hide-details="auto"
                    label="Inspected By"
                    item-text="full_name"
                    item-value="id"
                    outlined
                    dense
                    hide-no-data
                    @keyup.enter="fetchInspectedBy(['App Admin', 'Mechanic', 'Engineer'])"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="preRepairForm.control_number"
                    label="Control No"
                    outlined
                    dense
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </template>
  </v-card>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api'
import { mdiCalendarCheckOutline, mdiExportVariant, mdiSpeedometer, mdiClose, mdiCogOutline } from '@mdi/js'
import axios from '@axios'
import useEmployees from '../employees/useEmployees'
import useVehicles from '../vehicles/useVehicles'
import router from '@/router'
import usePreRepairs from './usePreRepairs'
import useUnits from './useUnits'

const today = new Date()
const formInit = () => ({
  id: null,
  defects: null,
  findings: null,
  control_number: null,
  issue_date: today.toISOString().substr(0, 10),
  completion_date: null,
  pre_repair_parts: [],
  prepared_by: null,
  inspected_by: null,
})

export default {
  setup() {
    const exporting = ref(false)
    const saving = ref(false)
    const vehicleTab = ref(null)
    const errors = ref([])
    const issueDatePicker = ref(false)
    const completionDatePicker = ref(false)
    const preRepairForm = ref(formInit())
    const preparedByOptions = ref([])
    const searchPreparedBy = ref(null)
    const loadingPreparedBy = ref(false)
    const inspectedByOptions = ref([])
    const searchInspectedBy = ref(null)
    const loadingInspectedBy = ref(false)
    const loadingEmployee = ref(false)
    const { employees, getEmployees, searchEmployee } = useEmployees()
    const { vehicles, getVehicle, getVehicles, searchVehicle, loading: loadingVehicle } = useVehicles()
    const { getPreRepair } = usePreRepairs()
    const { units, getUnits } = useUnits()
    const resetForm = () => {
      preRepairForm.value = formInit()
    }

    // alerts
    const showAlert = ref(false)
    const alertType = ref('success')

    const fetchVehicles = () => {
      getVehicles({ searchQuery: searchVehicle.value })
    }

    const fetchEmployees = roles => {
      loadingEmployee.value = true
      getEmployees({ roles, searchQuery: searchEmployee.value }).then(() => {
        loadingEmployee.value = false
      })
    }

    const fetchPreparedBy = roles => {
      loadingPreparedBy.value = true
      getEmployees({ roles, searchQuery: searchPreparedBy.value }).then(r => {
        preparedByOptions.value = r.data.data
        loadingPreparedBy.value = false
      })
    }

    const fetchInspectedBy = roles => {
      loadingInspectedBy.value = true
      getEmployees({ roles, searchQuery: searchInspectedBy.value }).then(r => {
        inspectedByOptions.value = r.data.data
        loadingInspectedBy.value = false
      })
    }

    const addNewPart = () => {
      preRepairForm.value.pre_repair_parts.push({
        unit: 'Piece',
        quantity: 1,
        description: null,
        preferred_brand: null,
      })
    }

    const refreshAlert = () => {
      showAlert.value = false
      alertType.value = 'success'
    }

    const exportPdf = () => {
      exporting.value = true
      window.location.href = `${process.env.VUE_APP_SERVER_URL}/export-pre-repair/${preRepairForm.value.id}`
      exporting.value = false
    }

    const save = () => {
      refreshAlert()
      saving.value = true
      if (preRepairForm.value.id) {
        axios
          .put(`/pre-repairs/${preRepairForm.value.id}`, preRepairForm.value)
          .then(() => {
            saving.value = false
            router.push({ name: 'pre-repairs' })
          })
          .catch(error => {
            if (error.response.status === 422) {
              errors.value = error.response.data.errors
            }
            saving.value = false
          })
      } else {
        axios
          .post('/pre-repairs', preRepairForm.value)
          .then(() => {
            saving.value = false
            router.push({ name: 'pre-repairs' })
          })
          .catch(error => {
            if (error.response.status === 422) {
              errors.value = error.response.data.errors
            }
            saving.value = false
          })
      }
    }

    onMounted(() => {
      getUnits()
    })

    if (router.currentRoute.params.id) {
      saving.value = true
      getPreRepair(router.currentRoute.params.id).then(response => {
        const { data } = response

        // const parts = data.pre_repair_parts.map(part => {
        //   const container = part
        //   container.id = part.part_id

        //   return container
        // })

        vehicles.value = data.vehicle ? [data.vehicle] : []
        employees.value = data.driver ? [data.driver] : []

        preparedByOptions.value = data.prepared_by ? [data.prepared_by] : []
        inspectedByOptions.value = data.inspected_by ? [data.inspected_by] : []

        preRepairForm.value = data
        preRepairForm.value.prepared_by = data.prepared_by?.id
        preRepairForm.value.inspected_by = data.inspected_by?.id

        saving.value = false
      })
    }

    if (router.currentRoute.query.vehicle_id) {
      getVehicle(router.currentRoute.query.vehicle_id).then(response => {
        vehicles.value = response.data ? [response.data] : []
        preRepairForm.value.vehicle_id = response.data.id
      })
    }

    return {
      // alerts
      showAlert,
      alertType,

      // variables
      preRepairForm,
      vehicleTab,
      errors,
      issueDatePicker,
      completionDatePicker,

      // functions
      save,
      addNewPart,
      saving,
      exporting,
      exportPdf,

      // usePreRepair
      getPreRepair,

      // useUnits
      units,
      getUnits,

      // useVehicles
      vehicles,
      fetchVehicles,
      searchVehicle,
      loadingVehicle,

      // useEmployees
      employees,
      getEmployees,
      searchEmployee,
      loadingEmployee,
      fetchEmployees,

      fetchPreparedBy,
      preparedByOptions,
      searchPreparedBy,
      loadingPreparedBy,

      fetchInspectedBy,
      inspectedByOptions,
      searchInspectedBy,
      loadingInspectedBy,

      resetForm,

      // icons
      icons: {
        mdiCalendarCheckOutline,
        mdiSpeedometer,
        mdiExportVariant,
        mdiCogOutline,
        mdiClose,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/invoice.scss';
.app-invoice-editable {
  .header-inputs {
    width: 122px;
  }

  .add-services-form {
    .header-spacer {
      // This is according to item actions width
      width: 39px;
    }
    .item-actions {
      @at-root {
        @include theme--child(add-services-form) using ($material) {
          .item-actions {
            border-left: thin solid map-deep-get($material, 'dividers');
          }
        }
      }
    }
  }
}
</style>
